.fade-enter {
    opacity: 0;
  }
  
  .fade-enter-active {
    opacity: 1;
    transition: opacity 300ms ease-in;
  }
  
  .fade-exit {
    opacity: 1;
  }
  
  .fade-exit-active {
    opacity: 0;
    transition: opacity 300ms ease-in;
  }
  
.slide-enter {
  opacity: 0;
  transform: translateX(100%);
}

.slide-enter-active {
  opacity: 1;
  transform: translateX(0);
  transition: opacity 2s, transform 2s;
}

.slide-exit {
  opacity: 1;
  transform: translateX(0);
}

.slide-exit-active {
  opacity: 0;
  transform: translateX(-100%);
  transition: opacity 2s, transform 2s;
}

.grow-enter {
  transform: scale(0);
  opacity: 0;
}

.grow-enter-active {
  transform: scale(1);
  opacity: 1;
  transition: transform 2000ms, opacity 2000ms;
}

.grow-exit {
  transform: scale(1);
  opacity: 1;
}

.grow-exit-active {
  transform: scale(0);
  opacity: 0;
  transition: transform 2000ms, opacity 2000ms;
}
